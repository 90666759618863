import useTranslation from 'next-translate/useTranslation'
import React, { useState, useEffect } from 'react'
import { getUserRecentSearchByUserId ,getUserRecentSearchForLocal,searchAnalyticEvent} from 'services/searchService'
import styles from './style.module.scss'
import { useAppContext } from 'context/AppContext'
import { getClientSideCityAuctions } from 'services/auctionService'
import { useRouter } from 'next/router'
import { getParamsFromQuery } from 'services/queryString'
import { listingsPerPageSize } from '@/utils/constant'

const RecentSearchNew = (props)=>{
    const  { propertyType,
        handleSetFilter,
        setInputValue,
        searchType,
        selectedCityId,
        toggleCityBackClick,
      } = props
      const router = useRouter()
      const { query } = router
      const routerParams = getParamsFromQuery(query)
    const { t, lang } = useTranslation('translations')
    const [recentSearch, setRecentSearch] = useState([])
    const { state, dispatch } = useAppContext()
    const { searchReducer,homeSearchReducer } = state

    useEffect(() => {
      if (!window.localStorage.getItem('authToken')) {
        getLocalRecentSearch()
      } else {
        getUserRecentSearch()
      }
    }, [searchReducer?.propertyFor])
  
    const filterBasedOnPropType = (tempRecentSearch) => {
      if (!window.localStorage.getItem('authToken')) {
        if (!tempRecentSearch) {

          setRecentSearch([])
          return
        }
        if (propertyType === 'sale') {
          const filtered = tempRecentSearch?.filter((ele) => ele?.propertyForSlug === 'sale')
          filteredRecentSearch(filtered)
        } else if (propertyType === 'rent') {
          const filtered = tempRecentSearch?.filter((ele) => ele?.propertyForSlug === 'rent')
          filteredRecentSearch(filtered)
        }
      } else {
        if (propertyType === 'sale') {
          filteredRecentSearch(tempRecentSearch?.buy)
        } else if (propertyType === 'rent') {
          filteredRecentSearch(tempRecentSearch?.rent)
        }
      }
    }

    const filteredRecentSearch = (tempData)=>{
      let uniqueData;
      if (searchType === 'city'){
        uniqueData = Array.from(new Map(tempData.map(item => [item.cityId.join(), item])).values());
      }
      else{
        uniqueData = tempData
      }

      if (uniqueData?.length > 0 ){
        let newRecentSearch = uniqueData.filter(obj => {
          if (searchType === 'city') {
            return (obj?.districtId?.length > 0 && obj?.cityId?.length) || getListingType(obj) === searchType && obj.cityId.length > 0;
        } else {
            return getListingType(obj) === searchType && +obj.cityId[0] === selectedCityId;
        }
        });
        setRecentSearch(newRecentSearch)
      }
    }
    
    useEffect(()=>{
      if (recentSearch?.length > 0){
        const tempLength = homeSearchReducer?.selectedDistrictsMsite.length
          if (searchType !== 'city' && tempLength > 0) {
            const matchedIndex = recentSearch?.findIndex((value)=>{
              if (value?.districtId?.includes(homeSearchReducer?.selectedDistrictsMsite[tempLength-1]?.districtId.toString())){
                return routerParams.propertyFor === value?.propertyForSlug && value?.cityId?.indexOf(routerParams?.cityId) >= 0
              }
            })
            if (matchedIndex >= 0){
              recentSearch.splice(matchedIndex,1)
            }
        }

      }
    },[ homeSearchReducer?.selectedDistrictsMsite])

    const getLabelData = (value)=>{
     
      if(value?.districtId?.length > 0) {
        if (searchType === 'city' && value?.cityId?.length){
          return value?.city?.join(',')
        }
        if(value?.districtId?.length === 1){
          return `${value?.district?.slice(0,1)} ${(value?.district?.length > 1 ) ? '...+'+(value?.district?.length-1) : ''}`// instead of districtName use disttrict
        }
          return `${value?.district.slice(0,1)}${(value?.district?.length > 1 ) ? '...+'+(value?.district?.length-1) : ''}`// instead of districtName use disttrict
      }
      else if(value?.city && value?.city?.length) {
        return value?.city?.join(',')
      }
        return value?.country?.join(',')||t('common.SAUDI_ARABIA')
    }
    const getListingType = (value) => {
      if (!window.localStorage.getItem('authToken')) {
        if (value?.districtId?.length > 0) {
          return 'district'
        } 
          return 'city'
      } 
        if ((value?.cityId?.length && value?.districtId?.length) || (value?.districtId?.length && !value?.cityId?.length)) {
          return 'district'
        } else if (value?.cityId && !value?.districtId?.length) {
          return 'city'
        }
    }
    
  
    const getLocalRecentSearch = async () => {

      const reqHeaders = {
        'Content-Type': 'application/json',
        cookie: `i18next=${lang}`,
        locale: lang,
      }
      let body = JSON.parse(window.localStorage.getItem('recentSearch'))

      if ( body === null || body === undefined ){
        body = {}
      }
      const userRecentSearch = await getUserRecentSearchForLocal(reqHeaders, body)

      filterBasedOnPropType(userRecentSearch?.recentSearches)
    }
    const getUserRecentSearch = async () => {
      const reqHeaders = {
        cookie: `i18next=${lang}`,
        locale: lang,
        authorization: window.localStorage.getItem('authToken') || '',
      }
  
      const userRecentSearch = await getUserRecentSearchByUserId(reqHeaders)
      
      filterBasedOnPropType(userRecentSearch)
    }
  
    const getPropertiesData = async (val) =>{
      const payload ={
        page: 0,
        pageSize: listingsPerPageSize || 32,
        propertyFor:val?.propertyForSlug || 'sale',
        type:"residential",
        includeProperties: true,
        cityId:val?.cityId[0] || null,
        districtId:val?.districtId || null,
        countryId:val?.countryId[0] || null,
        propertyTypeData: val?.propertySubTypeSlug || []
      }
      if(!val?.propertySubTypeSlug || val?.propertySubTypeSlug?.length == 0){
        delete payload.propertyTypeData ;
      }
      const res = await fetch(`/api/properties/filter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          locale: lang,
        },
        body: JSON.stringify(payload),
      })
      const response = await res?.json()
      const responseData = await response?.data;
      
      const data = await responseData?.data
  
      const auctionData = await getClientSideCityAuctions(lang, val?.cityId[0])
      
      handleSetFilter(data, auctionData)
    }
  
    const setSelectedDataByRecentSearch = async (val) =>{
      let dData = {};
     if(val?.locationType === 'district'){
      dData.districtId = val?.districtId?.map((m) => parseInt(m));
      dData.districtName = val?.district?.map((m) => `${m}${t('Details.COMMA')}${val?.city}` );
      dData.locationType = val?.locationType;
      dData.whichSelected = val?.locationType;
     }
     if(val?.locationType === 'city'){
      dData.cityId = val?.cityId;
      dData.cityName = val?.city;
      dData.locationType = val?.locationType;
      dData.whichSelected = val?.locationType;
      dData.popularCity = val?.popular
     }
     if(val?.locationType === 'country'){
      dData.countryId = val?.countryId;
      dData.countryName = val?.country;
      dData.locationType = val?.locationType;
      dData.whichSelected = val?.locationType;
     }
      dispatch({
        type: 'SELECTED_ITEM',
        payload: dData,
      })
    }
  
    const selectDistrict = (val,i)=>{

      const selectedDistrict ={
        "title": `${val?.district?.[i]}, ${val?.city[0]}`,
        "countryId": 1,
        "cityId": +val?.cityId[0],
        "districtId": +val?.districtId[i],
        "city": val?.city[0],
        "srpTitle": val?.district?.[i],
        "propertyFor": propertyType,
        "category": searchReducer?.propertyType,
        "locationType": "district",
        "searchType": "location",
    }

        let tempCheck = false;
        if (homeSearchReducer?.selectedDistrictsMsite.length > 0){
        homeSearchReducer?.selectedDistrictsMsite.map((obj)=>{ if (selectedDistrict?.districtId === obj?.districtId){
          tempCheck = true
        } })
        }
        if (!tempCheck) {
          setInputValue(selectedDistrict)
        }
    }
    const actionFunction = async (val) => {
  
       
      dispatch({ type: 'RECENT_SEARCH_VALUE', payload: val });
      await setSelectedDataByRecentSearch(val);
  
      handleSetFilter && await getPropertiesData(val) ;
      searchAnalyticEvent({...val,scrolled_depth:searchReducer?.scrollDepth},'recent search','',window,lang,'search')
  
      searchAnalyticEvent(val,'recent search','',window,lang,'recent_search_clicked')
     
      dispatch({ type: 'RECENT_SEARCH_TRIGGER', payload: true });
      toggleCityBackClick && toggleCityBackClick()
      const selectedCity = {
        "propertyFor": propertyType,
        "title": val?.city[0],
        "countryId": 1,
        "cityId": +val?.cityId[0],
        "districtId": null,
        "city": val?.city[0],
        "srpTitle": val?.city[0],
        "category": searchReducer?.propertyType,
        "locationType": "city", 
    }
    
      if (searchType === 'city'){
        dispatch({type:"REMOVE_ALL_SELECTED_DISTRICT",payload: []})
         dispatch({ type: "SET_ALL_DISTRICTS", payload: [] })
        setInputValue(selectedCity)
      }
      else{
        for (let i = 0;i < val?.districtId?.length; i++ ){
          selectDistrict(val,i)
        }
      }
      const selectedIndex = recentSearch.findIndex((element)=>{
        if(val?.cityId?.join(',') === element?.cityId?.join(',') && val?.districtId?.sort()?.join(',') === element?.districtId?.sort()?.join(',')){
          return routerParams.propertyFor === element?.propertyForSlug && element?.cityId?.indexOf(routerParams?.cityId) >= 0
        }
      })
      if(selectedIndex>=0){
        recentSearch.splice(selectedIndex,1)
      }
    }
    
    if (recentSearch?.length <= 0 || homeSearchReducer?.selectedDistrictsMsite.length >=5) return null
    return (
      <div className={styles.mobileTopLocation}>
        <p className={styles.suggestion}>{t('Search.RECENT_SEARCHES')}</p>
        <div className={styles.topCityButtonWrapper}>
        {recentSearch
          ?.sort((first, second) => second.searchTimestamp - first.searchTimestamp)
          .filter((_, index) => index < 3)
          .map((val, i) => (
            <div key={i} className={`${i === recentSearch?.length - 1 && val.propertySubTypeSlug?.length}`} onClick={() => actionFunction(val)}>
              <div className={styles.suggestion_list}>
                <span>{getLabelData(val)}</span>
              </div>
            </div>
          ))}
          </div>
      </div>
    )
  }

export default RecentSearchNew;
